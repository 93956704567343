import NumberFormat = Intl.NumberFormat;

import type { CostItemI } from "@/stores/manuals/ManualsInterface";

export function compactCostFormatter(code: string): NumberFormat {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    notation: 'compact',
    currency: code,
  });
}

export function costFormatter(code: string): NumberFormat {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: code,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function costFormatterExtended(price: CostItemI): string {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: price.currency.alpha,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price.cost)
}

export function costFormatterRu(): NumberFormat {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
  });
}

export function costTariff(): NumberFormat {
  return new Intl.NumberFormat("ru-RU", {});
}
